export const getWorkspaces = async (workspace_ids) => {
    let url = getUrl('/api/workspaces/');
    if ((typeof(workspace_ids) != 'undefined') && (workspace_ids.length > 0)) {
        url = url + '?ids=' + workspace_ids.join(',');
    }

    let response = {};
    try {
        response = await logFetchAJAX(url, {});
    } catch (ex) {

    }
    
    let result = [];
    if ((typeof(response) != 'undefined') && (response.hasOwnProperty('workspaces'))) {
        let workspace_dict = response['workspaces'];
        workspace_ids = Object.keys(workspace_dict);
        workspace_ids.forEach((e) => { 
            let workspace = workspace_dict[e] || null
            if (workspace != null) result.push(workspace);
        });    
    }
    
    return result;
}
